.header {
  grid-template-rows: 1fr auto;
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  overflow: visible;
}

.header-header {
  z-index: 5000;
  background-color: #fff;
  grid-area: 1 / 1 / 1 / 7;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  height: 57.9833px;
  display: grid;
  position: sticky;
  top: 0;
  box-shadow: 2px 2px 2px 1px #0003;
}

.header__photo {
  z-index: 10000;
  box-sizing: border-box;
  cursor: pointer;
  grid-area: 1 / 2 / 1 / 4;
  max-width: 100%;
  max-height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 30px;
  padding-right: 10px;
  transition: all 1s;
}

.header__photo:hover {
  opacity: .7;
  cursor: pointer;
  transition: all 1s;
}

.header__phone {
  display: none;
}

.header__phone img {
  box-sizing: border-box;
  max-width: 100%;
}

.header__phonecall {
  z-index: 3;
  cursor: pointer;
  width: 2em;
  position: fixed;
  top: .75em;
  left: 1.5em;
}

/*# sourceMappingURL=index.d0cc6f15.css.map */
